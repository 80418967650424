import React, { useState, useEffect, useContext, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
  selectListSx,
  formSx,
  inputLabelSx,
  menuItemSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  settingsElement,
  additionalSettings,
  toggle,
  toggleFullWidth,
  image,
} from "../style/pages/tag.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

import dataJson from "../data/tags.json";

function Tag() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "tag" }, name: { nin: ["main"] } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          name
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const containerTag = useRef();

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [tag, setTag] = useState("");
  const [idTag, setIdTag] = useState(1);
  const [individualTag, setIndividualToppers] = useState("");
  const [color, setColor] = useState("");
  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Выбор из заготовленных бирок
  const handleTag = (event) => {
    setTag(event.target.value);
    dataJson
      .filter((item) => item.info === event.target.value)
      .forEach((item) => setIdTag(item.id));
    setIndividualToppers("");
    setColor("");
  };

  // Обновить состояние для текста индивидуальной бирки
  const handleIndTag = (event) => {
    setIndividualToppers(event.target.value);
    setTag("");
    setColor("");
  };

  // Обновить состояние для цвета
  const handleColor = (event, newAlignment) => {
    setColor(newAlignment);
  };

  // Обновить состояние для количества топпера
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить первый набор опор
  const addProduts = () => {
    const dataOrder = {
      tag: tag || individualTag,
      color: color,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: { ...store.substrates },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      toppers: { ...store.toppers },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
      frame: { ...store.frame },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      tag: {
        bundle: [...store.tag.bundle, dataOrder],
        totalPrice: store.tag.totalPrice + dataOrder.totalAmount,
      },
    });

    setTag("");
    setIdTag(1);
    setIndividualToppers("");
    setColor(null);
    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.tag.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.tag.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      tag: {
        bundle: [...arrBundle],
        totalPrice: store.tag.totalPrice - deductibleAmount,
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      screens: { ...store.screens },
      substrates: { ...store.substrates },
      spatulas: { ...store.spatulas },
      toppers: { ...store.toppers },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    let q = quantity || 0;

    if (individualTag) {
      setTotalAmount(47 * q);
    } else if (tag && !individualTag) {
      dataJson
        .filter((item) => item.info === tag)
        .forEach((item) => setTotalAmount(item.price * q));
    }
  }, [quantity, tag, individualTag]);

  useEffect(() => {
    containerTag.current.scrollTo(0, 0);
  }, [tag]);

  return (
    <main className="mainpage">
      <MetaTag title="Бирки из акрила | Wowmilota" />
      <motion.div
        layoutId="tagContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
        ref={containerTag}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes
            .filter((item) => item.name === String(idTag))
            .map((img) => {
              const { childImageSharp } = img;
              const src = getImage(img);

              return (
                <motion.div
                  onClick={() => setSelectedImg(src)}
                  onKeyDown={() => setSelectedImg(src)}
                  className={previewImage}
                  key={childImageSharp.id}
                  whileHover={{ scale: 0.995 }}
                >
                  <GatsbyImage
                    image={src}
                    alt="Фотография бирок из акрила"
                    className={image}
                  />
                </motion.div>
              );
            })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <motion.div
                  className={detailHeader}
                  layout="position"
                  layoutId="tagTitle"
                >
                  <h2>Бирки из акрила</h2>
                </motion.div>
                <p>
                  Презентовать товар - это особое искусство. И в последнее время
                  этому отводится немалая часть в кондитерском деле. Хотите
                  красиво, стильно и дорого подать свой десерт? Мы знаем как.
                  <br />
                  Наши бирки из зеркального акрила добавят статусность,
                  изюминку, придадут тематический окрас десерту. Упаковку с
                  такими бирками хочется фотографировать, и делиться контактами
                  кондитера со всеми друзьями.
                  <br />
                  Мы можем изготовить бирки любой формы, с вашим лого или любым
                  текстом. Пишите нам свою идею и мы рассчитаем стоимость бирок
                  по вашему индивидуальному заказу.
                </p>
              </div>

              <h3 className={titleSettings}>
                Выберите готовый или кратко опишите индивидуальный набор бирок
              </h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: tag
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Готовый набор бирок</h3>
                  <FormControl variant="standard" fullWidth sx={formSx}>
                    <InputLabel id="tags-select-label" sx={inputLabelSx}>
                      Выберите набор бирок
                    </InputLabel>
                    <Select
                      sx={selectListSx}
                      labelId="tags-select-label"
                      id="tags"
                      value={tag}
                      label="Выберите набор бирок"
                      onChange={handleTag}
                    >
                      {dataJson.map((item, index) => {
                        const { info } = item;

                        return (
                          <MenuItem
                            sx={menuItemSx}
                            value={info}
                            key={`topper${index}`}
                          >
                            {info}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: individualTag
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Индивидуальный набор бирок</h3>
                  <div className={toggleFullWidth}>
                    <TextField
                      fullWidth
                      id="tag"
                      label="Опишите бирку"
                      variant="standard"
                      onChange={handleIndTag}
                      value={individualTag}
                      sx={textFieldSx}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: color
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Цвет</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={color}
                    exclusive
                    onChange={handleColor}
                    sx={toggleContainerSx}
                  >
                    <ToggleButton
                      value="Золото"
                      key={`color1`}
                      sx={toggleButtonSx}
                    >
                      Золото
                    </ToggleButton>
                    <ToggleButton
                      value="Серебро"
                      key={`color2`}
                      sx={toggleButtonSx}
                    >
                      Серебро
                    </ToggleButton>
                    <ToggleButton
                      value="Прозрачный"
                      key={`color3`}
                      sx={toggleButtonSx}
                    >
                      Прозрачный
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Материал</h3>
                  <p>Акрилл</p>
                </div>

                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Толщина</h3>
                  <p>3мм</p>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="tagQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.tag.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.tag.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.tag}</p>
                        <p>{item.color}</p>
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>{store?.tag.bundle.length}</p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.tag.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>{item.tag}</p>
                          <p>{item.color}</p>
                          <p>{item.quantity} шт.</p>
                          <p>от {item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 && (tag || individualTag) && color ? (
                <button className={costBundle} onClick={addProduts}>
                  {individualTag
                    ? totalAmount > 0
                      ? `Добавить набор от ${totalAmount} руб.`
                      : "Сформируйте набор"
                    : totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.tag.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Tag;
